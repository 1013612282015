.airwaybill-table-colomn {
  display: flex;
  max-width: 120px;
  font-size: 12px;
  font-family: "Poppins-Medium";
  cursor: pointer;
  text-transform: uppercase;
}

.airwaybill-table-item {
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.airwaybill-ratedesc-table-colomn {
  font-family: "Montserrat-Regular";
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}
.airwaybill-ratedesc-table-item {
}

.DataTable-row-light {
  color: #fff;
}

.DataTable-row-dark {
  color: #000;
}

.airwaybill-contactfinder-table-column {
  font-size: 11px;
  font-family: "Poppins-Medium";
  cursor: pointer;
  text-transform: uppercase;
}

.PrintLabelModal-txt1 {
  font-family: "Poppins-SemiBold";
  margin-bottom: 30px;
  font-size: 25px;
}

.PrintLabelModal-txt2 {
  font-family: "Poppins-SemiBold";
  margin-bottom: 15px;
  font-size: 15px;
}

.PrintLabelModal-txt3 {
  font-family: "Poppins-Regular";
  color: #000;
  font-size: 13px;
}

.awb-formlabel {
  margin-top: 150px;
}

.awb-form-dimension-label {
  display: flex;
}

.awb-form-dimension-label-1 {
  margin-inline: 40px;
}

.print-label-txt1 {
  font-size: 14px;
}

.print-label-txt2 {
  font-size: 12px;
  color: grey;
}

.print-manifest-box1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 30vh;
  overflow: hidden;
}

.print-manifest-img {
  height: 100px;
  object-fit: contain;
  width: 100px;
}

.print-document-image {
  width: 88mm;
  height: 55mm;
  margin: 10px;
  object-fit: contain;
  background-color: rgb(243, 243, 243);
}

.PrintDocumentModal-page {
  overflow: hidden;
  width: 210mm;
  height: 297mm;
  padding: 20px;
  border: 1px solid grey;
}

.PrintDocumentModal-Box {
  display: flex;
  flex-wrap: wrap;
}

.PrintDocumentModal-pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.print-document-img {
  width: 100mm;
  height: 75mm;
  margin: 10px;
  object-fit: contain;
}

.print-document-loader {
  height: 200px;
  object-fit: contain;
  width: 200px;
}

.print-deliver-list-txt1 {
  font-size: 26px;
  color: rgb(233, 80, 80);
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
  text-align: center;
}
.print-deliver-list-txt2 {
  font-family: "Montserrat-Regular";
  font-size: 13px;
}
.print-deliver-list-table-td {
  border: 1px solid rgb(233, 80, 80);
}

.print-deliver-list-page {
  padding: 20px;
  text-transform: uppercase;
}
.print-deliver-list-table {
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.label-border {
  border: 1px solid #000;
}

.print-awblabel-txt1 {
  font-family: "Montserrat-Regular";
  font-size: 12px;
}
.print-awblabel-txt2 {
  font-family: "Montserrat-Medium";
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.print-awblabel-txt3 {
  font-family: "Montserrat-Regular";
  font-size: 15px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: bolder;
}

.print-awblabel-logo-container {
  display: flex;
  justify-content: center;
  width: 101.6mm;
  object-fit: contain;
  height: 20mm;
}
.print-awblabel-logo {
  width: 100%;
}

.print-label-barcode {
  display: flex;
  align-content: center;
  justify-content: center;
}

/* 102mm x 127mm */
.delivery-list-table {
  border: 1px solid red;
  margin-top: 20px;
}

.delivery-list-table > thead > tr > th {
  border: 1px solid red;
}

.delivery-list-table > tbody > tr > td {
  border: 1px solid red;
}

.PrintAwbModal-txt2 {
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}

.PrintAwbModal-txt1 {
  font-size: 12px;
}

.print-awb-table {
  table-layout: fixed;
  border: 1px solid #000;
}

.print-awb-table > thead > tr > th {
  border: 1px solid #000;
}

.print-awb-row {
  border-bottom: 1px solid #000;
}

.print-awb-col {
  border-right: 1px solid #000;
}

.print-awb-border-top {
  border-top: 1px solid #000;
}

.print-awb-row-border-t {
  border-top: 1px solid #000;
}

.print-awb-shipper-acno-box {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}

.print-awb-txt1 {
  font-size: 12px;
}

.print-awb-txt2 {
  font-size: 15px;
  font-weight: 800;
}

.print-awb-txt3 {
  font-size: 10px;
  padding: 0;
}

.awb-subform-head-container {
  display: flex;
}

.awb-subform-head {
  color: #1e88e5;
  text-decoration: underline;
}

.rate-description-modal-txt1 {
  font-family: "Poppins-Medium";
  font-weight: 500;
}

.awb-form-box {
  border: 1px solid #353535;
  padding: 20px;
  margin: 20;
  height: 100%;
}

.awb-form-box2 {
  border: 1px solid #353535;
  padding: 20px;
  margin: 20;
}

.awb-form-box-legend {
  font-family: "Poppins-Regular";
  color: #000;
  font-size: 14px;
  background-color: #fff;
  width: fit-content;
  margin-top: -32px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.awb-formodal-form-row {
  margin-bottom: 11px;
}

.awb-form-ratedescription-row {
  padding: 10px;
  margin-bottom: 2px;
}

.awb-form-ratedescription-row-txt1 {
  font-size: 13px;
}

.awb-form-otherchargeform-txt1 {
  font-size: 10px;
  line-height: 10px;
}

.awb-form-table {
  table-layout: fixed;
  width: 100%;
}

.div-to-print {
  visibility: hidden;
  display: none;
}

@media print {
  .div-to-print {
    visibility: visible;
    display: block;
  }
  .print-awblabelpreview-table {
    height: 6in;
  }
  .print-awblabel-logo-container {
    display: flex;
    justify-content: center;
    width: 101.6mm;
    object-fit: contain;
    height: 20mm;
  }
  .div-tobe-printed {
    visibility: visible;
    display: block;
  }
}
.div-tobe-printed {
  visibility: hidden;
  display: none;
}
.print-customs-page {
  overflow: hidden;
  height: 297mm;
  width: 210mm;
  padding: 10px;
}
.print-customs-box {
  display: flex;
  height: 20vh;
  justify-content: center;
}

.print-customs-table {
  table-layout: fixed;
  width: 100%;
}

.print-customs-header1 {
  font-family: "Montserrat-Bold";
  font-size: 16px;
  color: rgb(39, 38, 38);
}

.print-customs-header2 {
  text-align: right;
  font-family: "Poppins-SemiBold";
  color: rgb(39, 38, 38);
}
.print-customs-h6 {
  color: #000;
}
.print-customs-h7 {
  text-align: center;
  font-weight: 600;
}
.print-customs-td {
  border: 1px solid rgb(191, 191, 191);
  padding: 2px;
}
.print-customs-txt1 {
  font-size: 10px;
}
.print-customs-txt2 {
  font-size: 12px;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
}

.print-customs-pod-box {
  padding: 10px;
  margin: -3px 5px -3px -3px;
  background: #8f8f8f;
}
.print-customs-companyseal {
  width: 30%;
}
.print-awblabel-page {
  width: 101.6mm;
  max-height: 152.4mm;
  page-break-after: always;
}

.print-awblabelpreview-table {
  table-layout: fixed;
  width: 100%;
}
.print-awblabelpreview-table-td {
  border: 1px solid black;
  padding: 5px;
}

.modal-table-header {
  font-size: "18px";
  color: "#000";
  font-weight: 600;
  margin-bottom: "10px";
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
