.route-side-bar-logo {
  width: 100%;
  height: 65px;
  object-fit: contain;
}
.navbar-container {
  display: flex;
  justify-content: space-between;
}
.navbar-container-icon {
  margin: 15px;
}

.headerLogoNew {
  width: 60%;
  height: auto;
  margin: 5%;
  padding: 5%;
}

.headerLogo {
  width: 30px;
  height: 50px;
}

.route-header-box2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.route-header-box3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.route-side-bar {
  /* margin-top: 2%; */
  background: #f2f2f2;
  height: 100vh;
  overflow: scroll;
}
.TabHeader-box1 {
  display: flex;
}

.collapseSidebar {
  padding: 0px;
  margin-right: -14px;
}

.route-side-bar::-webkit-scrollbar {
  width: 0px;
}

.route-sidebar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
  padding: 7px 10px;
  margin-left: 15px;
  font-size: 20px;
}

.active {
  background: #1e88e5;
  font-size: 20px;
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  color: #ffff;
}

.route-sidebar-item:hover {
  background-color: rgb(231, 245, 252);
  font-size: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #1e88e5;
  cursor: pointer;
}

.route-sidebar-item-txt {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  /* color: rgb(90, 90, 90); */
}

.siderbar-profile {
  background: #1e88e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}
.siderbar-profile-open {
  background: #1e88e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.siderbar-profileBox {
  margin-left: 14px;
  color: #fff;
  flex: 1;
  font-size: 18px;
  font-family: "Poppins-SemiBold";
}

.status {
  font-family: "Poppins-SemiBold";
  font-size: 8px;
  color: #bffdd0;
}

.pageName {
  text-transform: capitalize;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Montserrat-Bold";
  margin-left: 13px;
  margin-right: 17px;
  color: rgb(96, 96, 96);
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nameScrum {
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  border-radius: 6px;
}

.devider-ver {
  border-right: 2px solid black;
  height: 23px;
  /* margin-inline: 2%; */
}

.route-header-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PageHeader {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.PageHeader-box1 {
  display: flex;
  align-items: center;
}

.PageHeader-txt2 {
  font-family: "Montserrat-SemiBold";
  font-size: 12px;
  color: #1e88e5;
  margin-left: 17px;
  margin-right: 13px;
}

.arrowBack {
  background-color: #f2f2f2;
  border-radius: 6px;
  cursor: pointer;
  color: #1e88e5;
}

.dropdownAddbtn {
  color: green;
  padding: 10px;
  font-family: "Poppins-SemiBold";
}

.formTable {
  width: 100%;
  table-layout: fixed;
}
.navbar-container-box {
  display: flex;
  flex-direction: row;
}
.navbar-container-mail-icon {
  margin: 20px;
  cursor: pointer;
}
.route-header-popup-txt1 {
  font-family: "Campton-Book";
  text-transform: uppercase;
  letter-spacing: 0.1px;
}
.route-header-popup-txt2 {
  font-family: Arial, Helvetica, sans-serif;
  color: #5f6368;
  font-size: 14px;
  letter-spacing: 0.2px;
}
.route-header-popup-txt3 {
  font-family: "Campton-Book";
  text-transform: uppercase;
  font-size: 12px;
}
.navbar-container-mail-txt1 {
  font-size: 9px;
  font-family: "Campton-Book";
}
.navbar-container-mail-txt2 {
  font-family: "Montserrat-Bold";
}
.route-header-popup-btnbox {
  margin-top: 5px;
  margin-bottom: 20px;
}
.PageHeader-box2 {
  display: flex;
}
.PageHeader-box3 {
  display: flex;
  /* width: 30%; */
  /* width: calc(100vw - 80%); */
  justify-content: space-between;
}
.PageHeader-Search {
  border-radius: 10px !important;
  background-color: white;
  border: 1px solid rgb(219, 219, 219);
}
.PageHeader-Search:hover {
  border-color: #1e88e5 !important;
  border-top-color: rgb(53, 103, 150);
  border-right-color: rgb(53, 103, 150);
  border-bottom-color: rgb(53, 103, 150);
  border-left-color: rgb(53, 103, 150);
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalTxt1 {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins-Bold";
}

.modalTxt2 {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: grey;
  font-family: "Poppins-SemiBold";
  margin-bottom: 12px;
}
