.thirdparty-print-invoice {
    width: 210mm;
    overflow: visible;
    padding: 20px;
    height: auto;
}
.Form{
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}