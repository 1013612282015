.collection-table-column {
  display: flex;
  flex-direction: column;
  max-height: 100px;
}
.collection-table-item {
  font-family: "Campton-Light";
  text-transform: uppercase;
  font-size: 12px;
}
.collection-table-item2 {
  font-family: "Campton-Light";
  text-transform: uppercase;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collection-customerform-box {
  display: flex;
  justify-content: space-between;
}
.collection-customerform-box2 {
  display: flex;
  justify-content: flex-end;
}
.new-collection-form-txt1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Montserrat-Bold";
  color: #1e88e5;
}
.collection-customerform-txt1 {
  font-size: 23px;
  font-family: "Montserrat-Bold";
  color: #1e88e5;
}
.collection-customerform-icon {
  background: #fbfbfb;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.collection-customerform-formlabel {
  font-family: "Montserrat-Regular";
  font-weight: 600;
}
.find-customer-modal-box {
  display: flex;
  justify-content: space-between;
}
.find-customer-modal-txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
}
.find-customer-modal-table-txt1 {
  cursor: pointer;
}
.find-customer-modal-close-button {
  cursor: pointer;
  border: 1px solid #bfbfbf;
}
.label-red {
  color: #1e88e5;
  font-weight: 800;
}
.collection-fulldetails-txt1 {
  font-family: "Montserrat-Bold";
}
.collection-fulldetails-txt2 {
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
}
.boxForm-box1 {
  display: flex;
  justify-content: space-between;
}
.boxForm-reset-icon {
  cursor: pointer;
}

.CustomerForm-Select {
  border-radius: 8px !important;
  background-color: #f5f5f5;
}
.CustomerForm-Select:hover {
  border-color: #356796 !important;
  border-top-color: rgb(53, 103, 150);
  border-right-color: rgb(53, 103, 150);
  border-bottom-color: rgb(53, 103, 150);
  border-left-color: rgb(53, 103, 150);
}
.ShareBillModal-box {
  display: flex;
  justify-content: space-evenly;
}
.ShareBillModal-box2 {
  border-radius: 100%;
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}
.TabHeader-box1 {
  display: flex;
  justify-content: space-between;
}
.PaymentFormModal-box1 {
  padding: 30px;
  font-family: "Campton-Light";
}
.PaymentFormModal-txt1 {
  font-family: "Campton-Bold";
  font-size: 23px;
}

.PaymentFormModal-txt2 {
  font-family: "Campton-Book";
  font-size: 12px;
  margin-bottom: 2px;
  color: #817f7f;
}
