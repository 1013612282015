.bills-view-Panel-label {
  font-family: "Montserrat-Regular";
}

.bills-view-box1 {
  padding: 5px;
  background: cornflowerblue;
  border-radius: 6px;
  font-family: "Montserrat-Regular";
}

.bills-view-logo {
  height: 30px;
  object-fit: contain;
}

.bills-view-table-headertxt {
  text-align: right;
  width: 100%;
}

.bills-view-table-headertxt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 9px;
}

.bills-view-txt1 {
  font-family: "Montserrat-Medium" !important;
  font-weight: 600;
  font-size: 10px;
}

.bills-view-txt2 {
  font-family: "Montserrat-Regular";
  font-weight: 500;
  font-size: 9px;
}

.bills-view-txt3 {
  font-family: "Montserrat-Regular";
  font-size: 10px;
  text-transform: uppercase;
}

.bills-view-txt4 {
  font-family: "Montserrat-Bold";
  text-align: center;
  font-size: 11px;
}

.bills-view-txt5 {
  font-family: "Montserrat-Regular";
  font-size: 6px;
}

.Bills-box1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}