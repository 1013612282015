body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./Assets/Fonts/Poppins-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url(./Assets/Fonts/Poppins-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./Assets/Fonts/Poppins-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./Assets/Fonts/Poppins-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./Assets/Fonts/Poppins-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(./Assets/Fonts/Montserrat-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url(./Assets/Fonts/Montserrat-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./Assets/Fonts/Montserrat-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(./Assets/Fonts/Montserrat-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "RedHatDisplay-Regular";
  src: local("RedHatDisplay-Regular"),
    url(./Assets/Fonts/RedHatDisplay-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Campton-Light";
  src: local("Campton-Light"),
    url(./Assets/Fonts/Campton-Light.otf) format("opentype");
}
@font-face {
  font-family: "Campton-Bold";
  src: local("Campton-Bold"),
    url(./Assets/Fonts/Campton-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Campton-Book";
  src: local("Campton-Book"),
    url(./Assets/Fonts/Campton-Book.otf) format("opentype");
}

.Box {
  min-height: 75vh;
}

.devider-ver {
  margin: 10px;
  color: rgb(168, 168, 168);
}

.txt1 {
  font-size: 12px;
}

.txt2 {
  font-size: 15px;
  font-weight: bold;
}

.txt3 {
  font-size: 12px;
}

.txt4 {
  font-size: 20px;
  font-weight: bold;
}

.txt5 {
  font-size: 30px;
  font-family: "Poppins-Bold";
}

.label {
  color: grey;
  font-size: 14px;
}

.FormSubHeading {
  font-size: 23px;
  font-family: "Poppins-Bold";
  /* border-bottom: 2px solid rgb(232, 232, 232); */
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1e88e5;
  margin-bottom: 5px;
  padding-top: 10px;
}

.error {
  color: red;
}

.tableAction {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  cursor: pointer;
}

.FormItemBox {
  background-color: rgb(246, 246, 246);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 2px;
}

.FormItemBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #1e88e5;
  margin-bottom: 10px;
  padding: 10px 5px 10px 5px;
}

.FormItemBoxHeadertxt {
  color: #fff;
  font-size: 16px;
  font-family: "Poppins-SemiBold";
  margin-left: 10px;
}

.FormBoxHeader {
  background-color: #1e88e5;
  border: #1e88e5;
}

.newBoxBtn {
  background: #1e88e5;
  border-color: #1e88e5;
  border-radius: 4px;
}

.newBoxSpan {
  color: #fff;
  font-family: Poppins-SemiBold;
  margin-left: 8px;
}

.boxformFinalBtn {
  display: flex;
  flex-direction: row-reverse;
}

.boxformfooter {
  border-top: 1px solid black;
}

.FormBoxFooter {
  background-color: rgb(240, 240, 240);
}

.formlabel {
  margin-bottom: 5px;
}

.tableHeading {
  color: gray;
  font-size: 13px;
}

.tableBox {
  background-color: #fff;
  padding-bottom: 10px;
}

.Assign-Box-heading {
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}

.DetailsMoal {
  text-transform: uppercase;
}

.modalTitle {
  color: #fff;
  font-weight: 550;
  font-size: 18px;
}

.newFormModalTitle {
  text-align: center;
  align-items: center;
  color: #fff;
  font-family: "Poppins-SemiBold";
}
.data-table-items {
  font-family: "Campton-Light";
  text-transform: uppercase;
}
.form-item-label {
  font-family: "Montserrat-Regular";
}
.Report-table-txt1 {
  font-family: "Montserrat-Bold";
}
.Report-table-txt2 {
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
}
