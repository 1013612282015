.footer {
  border-top: 1px solid rgb(235, 235, 235);
  padding: 20px;
}

.LoadingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70vh;
}
.keyboard-shortcuts-box {
  background: #fafafa;
  border-radius: 8px;
  box-shadow: 1px solid black;
}
