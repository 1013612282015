.Login-box {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-around;
}

.Login-box2 {
  width: 40%;
}

.Login-txt {
  color: gray;
  font-size: 9px;
  text-align: center;
}

.Login-box3 {
  background-color: rgb(240, 240, 240);
  width: 100%;
  min-height: 30vh;
  padding: 20px;
  border-radius: 2px;
}

.Login-txt-2 {
  color: gray;
  font-size: 14px;
}

.Login-txt-3 {
  color: gray;
  font-size: 10px;
}

.Register-box {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Register-box2 {
  width: 70%;
}

.Register-box3 {
  border-left: 10px solid #1e88e5;
}

.Register-box-txt1 {
  font-family: Poppins-Bold;
  font-size: 20px;
  margin-left: 5px;
}

.Register-box-txt2 {
  font-family: Poppins-Regular;
  font-size: 14px;
  font-weight: 599;
}

.bg-Col {
  background: url("../../Assets/Images/sign\ in\ bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.sahari-Login-Screen-Logo {
  background: url("../../Assets/Images/sahariLoginlogo.svg");
  height: 8vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.RegisterModal-Select {
  border-radius: 8px !important;
  background-color: white;
  border: 1px solid #d9d9d9;
}
.RegisterModal-Select:hover {
  border-color: #1e88e5 !important;
  border-top-color: #1e88e5;
  border-right-color: #1e88e5;
  border-bottom-color: #1e88e5;
  border-left-color: #1e88e5;
}

@media screen and (max-width: 600px) {
  .Login-box2 {
    width: 100%;
  }
}
