.PickupScreen-FormModal-Select {
  border-radius: 8px !important;
  background-color: white;
  border: 1px solid #d9d9d9;
  margin: 0 !important;
}

.PickupScreen-FormModal-Select :hover {
  border-color: #1e88e5 !important;
  border-top-color: #1e88e5;
  border-right-color: #1e88e5;
  border-bottom-color: #1e88e5;
  border-left-color: #1e88e5;
}

.PickupScreen-FormModal-txt1 {
  font-family: "Campton-Book";
  font-size: 20px;
}

.PickupScreen-FormModal-txt2 {
  font-family: "Campton-Light";
}

.PickupScreen-FormModal-txt3 {
  text-transform: uppercase;
  font-family: "Campton-Bold";
}

.PickupScreen-DataTable-box1 {
  display: flex;
  flex-direction: column;
}

.PickupScreen-DataTable-box2 {
  display: flex;
  justify-content: center;
  font-size: 9px;
}

.PickupScreen-DataTable-pickedup {
  font-family: "Montserrat-Medium";
  color: #3cb043;
}

.PickupScreen-DataTable-txt1 {
  text-transform: uppercase;
}