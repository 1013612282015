.detailed-tracking-saerch-box {
  display: flex;
  justify-content: center;
}
.detailed-tracking-saerch-box-form {
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 9px;
  overflow: hidden;
  margin-bottom: 20px;
  font-family: "Montserrat-Regular";
}
.DetailedTracking-DataTable-txt1 {
  font-family: "Montserrat-Medium";
  font-size: 20px;
  margin-bottom: 5px;
}
.DetailedTracking-txt1 {
  font-family: "Montserrat-Regular";
}
.DetailedTracking-txt2 {
  font-family: "Montserrat-Regular";
  font-size: 12px;
}
.DetailedTracking-txt3 {
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
}

.head-txt {
  font-family: "Montserrat-SemiBold";
  color: #000;
  font-size: 12;
  color: #1e88e5;
}

.track-img {
  margin-bottom: 20px;
  width: 100%;
}

.no-data {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.track-txt1 {
  font-family: "Montserrat-Regular";
  color: #000;
  font-size: 15px;
  padding-bottom: 5px;
}
