.AssignBox-box1 {
  height: 68vh;
  overflow: auto;
}

.AssignBox-box2 {
  background: #fff;
  box-shadow: 2px 2px 5px #000;
  min-height: 100px;
  padding: 10px;
  margin: 10px;
  cursor: grab;
  display: flex;
  flex-direction: row;
}

.AssignBox-box3 {
  background: #f2f2f2;
  border-radius: 100%;
  padding: 20px;
}

.AssignBox-box4 {
  border-right: 1px solid #000;
  margin-right: 20px;
  margin-left: 30px;
}

.AssignBox-box5 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.AssignBox-box7 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.AssignBox-box6 {
  display: flex;
}

.AssignBox-box8 {
  height: 68vh;
  overflow: auto;
}

.AssignBox-box9 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.AssignBox-box10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AssignBox-box11 {
  border: 1px solid #dcdcdc;
  cursor: pointer;
  padding: 4px 5px;
  border-radius: 6px;
}

.AssignBox-borderedSelect {
  border: 1px solid #dcdcdc;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.AssignBox-box1::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.AssignBox-box1::-webkit-scrollbar-thumb {
  background: #1e88e5;
}

.AssignBox-box8::-webkit-scrollbar-thumb {
  background: #9c27b0;
}

.AssignBox-txt1 {
  font-size: 10px;
  font-family: "Montserrat-Bold";
}

.AssignBox-txt2 {
  font-size: 10px;
}

.AssignBox-txt3 {
  font-size: 10px;
  font-family: "Montserrat-Bold";
}

.AssignBox-txt4 {
  font-family: "Montserrat-Bold";
  text-align: center;
}

.AssignBox-txt5 {
  font-family: "Montserrat-Bold";
  color: #1e88e5;
  text-transform: uppercase;
}

.AssignBox-txttrips {
  font-family: "Montserrat-Bold";
  color: #1e88e5;
  text-transform: uppercase;
  font-size: 10px;
}