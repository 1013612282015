.PrintRefNumberModal-container {
  max-height: 101.6mm;
  max-width: 101.6mm;
  margin-top: 10px;
  page-break-after: always;
}
.PrintRefNumberModal-table {
  table-layout: fixed;
  width: 100%;
}
.PrintRefNumberModal-txt1 {
  font-family: "Montserrat-Bold";
  font-size: 35px;
  width: 100%;
}
.PrintRefNumberModal-box1 {
  height: 38.1mm;
  text-align: center;
  width: 100%;
}
.PrintRefNumberModal-box2 {
  height: 25.4mm;
}
.PdfRefNumModal-box1 {
  display: flex;
  width: 100%;
  flex-direction: column;
}
