.card1 {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
}

.card-header {
  font-size: 18px;
  font-family: "Montserrat-SemiBold";
}

.text-item {
  padding: 5px;
  border-radius: 5px;
}

.text-label {
  font-size: 13px;
  color: #000;
  font-family: "Montserrat-Regular";
}

.text-value {
  font-size: 17px;
  color: #000;
  font-family: "Montserrat-SemiBold";
}

.txt1 {
  font-size: 20px;
  color: #000;
  font-family: "Montserrat-SemiBold";
}

.module-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
}

.checkbox {
  width: 50px;
}

.module-name {
}

.MC-custom-table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-td {
  border: 10px solid #fff;
  border-left: 0px;
  padding: 7px;
  background-color: #f3f3f3;
  color: grey;
  font-size: 16px;
  text-transform: capitalize;
}

.MC-table-Txt {
  font-family: "Montserrat-SemiBold";
  letter-spacing: 0px;
  color: #000000;
  padding-left: 10px;
  cursor: pointer;
}
.MC-table-Txt:hover {
  /* background-color: #d8d8d8; */
}

.submit-btn {
  background-color: #fff;
  border: 1px solid #1e88e5;
  color: #000;
  width: 100%;
  height: 35px;
  border: 0px;
}
