.Reports-Datatable-col {
  display: flex;
  max-width: 120px;
}
.Reports-Datatable-txt1 {
  font-size: 12px;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Reports-Datatable-box1 {
  display: flex;
  justify-content: flex-end;
}
.Reports-Datatable-items {
  font-family: "Montserrat-Regular";
  font-size: 12px;
}

@media print {
  .Reports-Datatable-box1,
  .Reports-Datatable-box1 * {
    display: none !important;
  }
}

.borderedSelect {
  border-radius: 10px !important;
  background-color: white;
  border: 1px solid darkgray;
}
.borderedSelect:hover {
  border-color: #356796 !important;
  border-top-color: rgb(53, 103, 150);
  border-right-color: rgb(53, 103, 150);
  border-bottom-color: rgb(53, 103, 150);
  border-left-color: rgb(53, 103, 150);
}

.Reports-MenuBar-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}
