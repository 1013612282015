.Tracking-container {
  display: flex;
  flex-direction: column;
  background: rgb(250, 250, 250);
}
.Tracking-box1 {
  display: flex;
  min-height: 5vh;
  flex-direction: column;
}
.Tracking-box2 {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  padding: 50px;
}
.Tracking-box3 {
  display: flex;
  min-height: 5vh;
}
.Tracking-box4 {
  background: rgb(256, 256, 256);
  width: 100%;
  border-radius: 8px;
  border: 1px solid #1e88e5;
}
.Tracking-box5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1e88e5;
  width: 100%;
  height: 50px;
  border-radius: 12px 12px 0px 0px;
}
.Tracking-box6 {
  display: flex;
  justify-content: center;
  align-content: center;
}
.Tracking-txt1 {
  color: #fff;
  font-family: "Montserrat-Medium";
  text-align: center;
}
.Tracking-txt2 {
  color: #000;
  font-family: "Montserrat-Regular";
}
.Tracking-txt3 {
  font-family: "Montserrat-Bold";
}
.Tracking-table1 {
  width: 100%;
  table-layout: fixed;
}
.Tracking-icon-box {
  cursor: pointer;
}

/* new */
.trackBox-dot-active {
  background-color: #bbbbbb;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  margin-left: -22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.trackBox-box-active {
  border-left: 4px solid #f2f2f2;
  padding: 0px 0px 100px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.trackBox-box {
  border-left: 4px solid #bbbbbb;
  padding: 0px 0px 100px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.trackBox-box-content {
  margin-top: -10px;
  overflow-wrap: break-word;
}
.trackBox-box-content-sub {
  margin-left: 30px;
  margin-top: 20px;
}
.Formtxt5 {
  color: #707070;
  font-size: 14px;
  margin-bottom: 5px;
}
.Formtxt6 {
  color: #444444;
  font-size: 15px;
  font-weight: 600;
}
