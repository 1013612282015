.home-card {
	background-color: #f1f1f1;
	padding: 10px 20px 10px 20px;
	border-radius: 6px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	border-left: 5px solid #5173d4;
	/* height: 80px; */
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.home-card:hover {
	box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
}

.home-card-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.gray-bold {
	font-family: Poppins-Bold;
	color: #787878;
}

.tab-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.collection-icon {
	background: url("../../Assets/Images/Collection\ Grey.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	width: 1.3em;
	height: 1.5em;
	margin-right: 5px;
}

.collection-icon :hover {
	background: url("../../Assets/Images/Collection\ White.svg") !important;
}

.process-icon {
	background: url("../../Assets/Images/Process\ Grey.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	width: 1.5em;
	height: 1.5em;
	margin-right: 5px;
}

.process-icon ::selection {
	background: url("../../Assets/Images/Process\ White.svg");
}

.delivery-icon {
	background: url("../../Assets/Images/Delivery\ Grey.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	width: 1.5em;
	height: 1.5em;
	margin-right: 5px;
	margin-top: 5px;
}

.new-request-icon {
	background-repeat: no-repeat;
	background-size: 100%;
	width: 1.5em;
	height: 1.9em;
}

.new-request-text {
	font-size: 18px;
	font-family: Poppins-Regular;
	font-weight: 200;
	flex: 1;
}

.new-request-count {
	font-size: 20px;
	font-family: Poppins-bold;
}
.cardIcons {
	color: #dddfeb;
}

/* antd custom  */
/* .dashboard-date  */
.ant-picker-panel-container {
	border-radius: 9px;
}

.ant-picker-panel .ant-picker-footer {
	border-top: none;
	/* background: #1e88e5;
  border-color: #1e88e5;
  color: #fff; */
}
.trackOrderInputHead {
	width: 20%;
}
.trackBtnHead {
	display: flex;
	justify-content: center;
}
.trackBox {
	width: 90%;
	/* height: 180px; */
	background-color: #000;
	color: #ffff;
	border-radius: 6px;
	/* padding: 15px; */
}
.trackingDetailsBox {
	width: 90%;
	/* height: 180px; */
	background-color: #f2f3f5;
	/* color: #ffff; */
	border-radius: 6px;
	padding: 15px;
}
.deliveredBtn {
	height: 35px;
	width: 100px;
	background-color: #c8fad9;
	color: #018b71;
	border-radius: 4px;
}
.custom-timeline {
	display: flex;
	flex-direction: column;
}

.custom-timeline-item {
	position: relative;
	padding: 16px;
	border-left: 2px dotted #1890ff;
	margin-left: 20px;
	background-color: #f0f0f0;
}

.custom-timeline-item:first-child {
	margin-top: 0;
}

.custom-timeline-marker {
	width: 12px;
	height: 12px;
	border: 2px solid #1890ff;
	background-color: #fff;
	border-radius: 2px;
	position: absolute;
	left: -7px;
	top: 22%;
	transform: translateY(-50%);
}

.custom-timeline-content {
	padding-left: 12px;
}

.ant-steps-item-title {
	width: 100%;
}
.helpBox {
	display: flex;
	background-color: #c8fad9;
	border-radius: 4px;
	height: 100px;
	padding: 20px 20px 20px 10px;
}
.helpTxtBold {
	font-weight: bold;
	font-size: medium;
}
.heading {
	font-size: 18px;
	font-weight: bold;
}
.boldTxt {
	font-weight: bold;
	font-size: 18px;
}
