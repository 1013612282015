.header_div {
  display: flex;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.left {
  flex: 3;
  justify-content: space-between;
  padding-bottom: 5px;
}

.right {
  flex: 1;

  padding: 5px;
  padding-bottom: 5px;
}
.reightTop {
  padding-bottom: 5px;
}
.reightleft {
  padding-bottom: 10px;
}
/* .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
} */

.table_item {
  background-color: rgb(221, 191, 205);
  color: rgb(44, 39, 39);
  border-radius: 5px;
}

/* .ant-table-tbody{
  padding: 0 !important; 
}
.ant-table-tbody > tr > td{
  padding: 0 !important; 
} */

/* #my-table.ant-table-tbody{
  padding: 0 !important; 
}
#my-table.ant-table-tbody > tr > td{
  padding: 0 !important; 
} */
/* .my-table .ant-table-tbody {
  padding: 0 !important;

} */

.my-table .ant-table-tbody > tr > td {
  padding: 0 !important;
  height: 20px;
}

.cell {
  padding: 2px !important;
}

.my-summary-row {
  padding: 2px !important;
}

.my-summary-row .cell {
  padding: 2px !important;
}
.hidden {
  display: none;
}
.bold-input {
  font-weight: bold;
}
