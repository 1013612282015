.trip-form-button-label {
  font-size: 10px;
  font-family: "Montserrat-Regular";
}
.PrintTripSheet-page {
  overflow: hidden;
  width: 210mm;
  min-height: 300mm;
  padding: 20px;
}
.PrintTripSheet-table {
  table-layout: fixed;
  width: 100%;
}
.PrintTripSheet-table-td {
  border: 1px solid rgb(173, 173, 173);
}
.PrintTripSheet-txt1 {
  font-family: "Montserrat-Medium";
  font-size: 16px;
  text-transform: uppercase;
}
.Trip-DataTable-column {
  cursor: pointer;
}

.trip-print-taxInvoice-modal {
  min-height: 45vh;
  margin: 20px;
  font-family: "Poppins-Regular";
  text-transform: uppercase;
}
