.boxes-datatable-column {
  display: flex;
  font-family: "Campton-Light";
  text-transform: uppercase;
  font-size: 12px;
}

.updated {
  color: #11e24c;
}

.boxes-table-item {
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transaction-label {
  display: flex;
  justify-content: center;
  font-family: "Poppins-Bold";
  font-size: 16px;
}

.InvoiceLogo {
  position: relative;
  width: 190px;
  height: 90px;
  object-fit: contain;
}

.bordered {
  border: 1px solid #000;
  width: min-content;
}

.borderRight {
  border-right: 1px solid #000;
}

.ant-popover {
  border-radius: 12px;
}

.printIcons {
  position: absolute;
  left: 0%;
}

/* antd table design alter */
/* ============= */
.ant-table-thead .ant-table-cell {
  /* background-color: #f3f3f3; */
  font-weight: 900;
  color: #424954;
  font-size: 13px;
  border-bottom: 2px solid grey;
}

.ant-table-filter-trigger.active {
  color: #000 !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #000 !important;
}

/* =========== */
.uploadBtnSpan {
  margin-left: 8%;
}

.printBtnSpan {
  margin-inline: 4%;
  margin-left: 10%;
}

.table-row-light {
  background-color: #ffff;
}

.table-row-dark {
  background-color: #f9f9f9;
}

.kyc-update-avatar {
  display: flex;
  justify-content: center;
  background: rgb(250, 250, 250);
  border-radius: 8px;
  height: 141px;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.kyc-update-image-holder {
  display: flex;
  justify-content: center;
  background: rgb(230, 230, 230);
  border-radius: 8px;
  height: 145px;
}

.kyc-update-txt1 {
  font-family: "Montserrat-Regular";
  margin-bottom: 0rem;
}

.kyc-update-txt2 {
  font-family: "Poppins-SemiBold";
  font-size: 9px;
}

.kyc-update-image {
  width: 100%;
  height: 140px;
}

.kyc-update-alt-image {
  width: 100px;
  height: 100px;
}

@media print {
  .print-box-label-container {
    width: 101mm;
    height: 147mm;
  }

  .print-box-bill-container {
    width: 50.8mm;
    height: 76.2mm;
  }

  .print-invoice-qr {
    width: auto;
    height: 13vh;
  }
}

.print-box-label-container {
  width: 101mm;
  height: 147mm;
}

.print-box-label-table {
  table-layout: fixed;
  width: 100%;
  margin-left: 5px;
}

.print-box-label-txt1 {
  font-size: 22px;
  font-family: "Montserrat-Bold";
}

.print-box-label-txtMax {
  font-size: 50px;
  font-family: "Montserrat-Bold";
}

.print-box-label-txt2 {
  font-size: 11px;
  font-family: "Montserrat-Regular";
  font-weight: 800;
}

.print-box-label-txt3 {
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
}

.print-box-label-txt4 {
  font-family: "Montserrat-Regular";
  font-weight: 300;
  font-size: 20px;
}

.print-box-label-txt5 {
  font-family: "Montserrat-Regular";
  font-size: 14px;
}

.print-box-label-row {
  display: flex;
  justify-content: space-between;
}

.print-taxInvoice-modal {
  min-height: 90vh;
  margin: 20px;
  font-family: "Poppins-Regular";
}

.print-taxInvoice-table {
  padding: 10px;
  table-layout: fixed;
  width: 100%;
}

.print-taxInvoice-table-headertxt {
  width: 100%;
  text-align: right;
  padding: 10px;
}

.print-taxInvoice-modal-heading {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.print-taxInvoice-table-txt1 {
  font-family: "Montserrat-Medium" !important;
  font-weight: 600;
}

.print-taxInvoice-txt3 {
  font-size: 6vh;
  font-style: bold;
  font-weight: 800;
  font-family: "Poppins-Bold";
}

.print-invoice-qr {
  text-align: right;
  width: auto;
  height: 100px;
}

/* print invoice styles */
.print-invoice-page {
  overflow: hidden;
  width: 210mm;
  height: 300mm;
  padding: 20px;
}

.print-invoice-table-headertxt {
  text-align: right;
  width: 100%;
}

.print-invoice-table-headertxt1 {
  font-family: "Montserrat-SemiBold";
}

.print-invoice-table {
  table-layout: fixed;
  width: 100%;
}

.print-invoice-table-row {
  display: flex;
}

.print-invoice-table-column {
  flex: 50%;
}

.print-invoice-table-td {
  border: 1px solid rgb(173, 173, 173);
}

.print-invoice-txt1 {
  font-family: "Montserrat-Medium" !important;
  font-weight: 600;
}

.print-invoice-txt2 {
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  font-weight: 500;
}

.print-invoice-txt3 {
  font-family: "Montserrat-Regular";
  font-size: 11px;
  text-transform: uppercase;
}

.print-invoice-txt4 {
  font-family: "Montserrat-Bold";
  text-align: center;
}

.print-invoice-txt5 {
  font-family: "Montserrat-Regular";
  font-size: 9px;
}

.print-customsInvoice-txt1 {
  font-family: "Montserrat-Bold";
  font-size: 18px;
}

.print-customsInvoice-header {
  text-align: right;
}

.print-customsInvoice-txt2 {
  font-family: "Montserrat-Regular";
  font-weight: 600;
  font-size: 11px;
}

.print-customsInvoice-txt3 {
  font-family: "RedHatDisplay-Regular";
  text-transform: uppercase;
  padding: 2px;
}

.print-customsInvoice-txt4 {
  padding: 2px;
  font-family: "RedHatDisplay-Regular";
}

.print-customsInvoice-txt5 {
  padding: 2px;
  font-family: "RedHatDisplay-Regular";
}

.print-customsInvoice-txt6 {
  padding: 2px;
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
  font-size: 10px;
}
.print-customsInvoice-txt7 {
  text-transform: uppercase;
}

.print-customs-seal {
  width: 30%;
}

.print-customsInvoice-pod-box {
  padding: 10px;
  margin: -1px 5px -2px -1px;
  background: #8f8f8f;
}

.print-box-num-container {
  width: 50.8mm;
  height: 76.2mm;
}

.print-box-num-table {
  table-layout: auto;
  width: 100%;
}

.print-box-num-txt1 {
  font-family: "Poppins-Bold";
  font-size: 70px;
}

.print-invoice-signature {
  width: 100%;
  height: 150px;
}

/* Details modal style */
.box-details-txt1 {
  font-family: "Montserrat-Medium";
}

.box-details-txt2 {
  font-family: "Montserrat-Regular";
  text-transform: uppercase;
}

.UploadKYCModal-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  border: 1px solid #1e88e5;
  border-style: dotted;
  border-radius: 8px;
  margin-bottom: 10px;
}

.UploadKYCModal-box2 {
}

.UploadKYCModal-image1 {
  width: 100%;
}

.UploadKYCModal-txt1 {
  font-family: "Campton-Book";
  font-size: 18px;
}

.ImgRotateAndCrop-box1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ImgRotateAndCrop-box2 {
  min-height: 100px;
  /* width: 100%; */
  border: 1px solid #1e88e5;
  border-style: dashed;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex: 1;
}

.ImgRotateAndCrop-box3 {
  display: flex;
  justify-content: center;
}
.ImgRotateAndCrop-box4 {
  text-align: center;
}
.ImgRotateAndCrop-box5 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 10px;
  height: 400px;
  width: 400px;
}
.ImgRotateAndCrop-button1 {
  background: linear-gradient(to right, #53cde1, #2caee2);
  border-radius: 8px;
  border: 0;
}
.ImgRotateAndCrop-button2 {
  background: linear-gradient(to right, #2caee2, #53cde1);
  border-radius: 8px;
  border: 0;
}

.ImgRotateAndCrop-txt1 {
  font-family: "Campton-Bold";
  font-size: 16px;
}
.ImgRotateAndCrop-txt2 {
  font-family: "RedHatDisplay-Regular";
  font-size: 9px;
}
.text2 {
  font-size: 13px;
  width: 150px;
}
